import React from "react";
import "./App.css";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ListArticles from "./pages/ListArticles";
import {
  listCorporatePosts,
  getCorporatePost,
  listWeddingsPosts,
  getWeddingsPost,
  listPrivatePosts,
  getPrivatePost,
  listNonProfitPosts,
  getNonProfitPost,
} from "./graphql/queries";
import {
  createCorporatePost,
  createNonProfitPost,
  createPrivatePost,
  createWeddingsPost,
  deleteCorporatePost,
  deleteNonProfitPost,
  deletePrivatePost,
  deleteWeddingsPost,
  updateCorporatePost,
  updateNonProfitPost,
  updatePrivatePost,
  updateWeddingsPost,
} from "./graphql/mutations";
import ChangeArticle from "./pages/ChangeArticle";
import Main from "./pages/Main";
import Layout from "./components/Layout";
import {
  getUpdatedData,
  initialFormStateWeddings,
  initialFormStateCorporate,
  initialFormPrivate,
  initialFormStateNonprofit,
} from "./utils/getUpdatedDataValues";
import Category from "./pages/Category";

function App({ signOut }) {
  return (
    <Router>
      <Layout signOut={signOut}>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route
            path="/corporate/:id"
            element={<Category category="corporate" />}
          />
          <Route
            path="/weddings/:id"
            element={<Category category="weddings" />}
          />
          <Route
            path="/private/:id"
            element={<Category category="private" />}
          />
          <Route
            path="/nonProfit/:id"
            element={<Category category="nonProfit" />}
          />
          <Route
            path="/corporate/:id/blog/:blogId"
            element={
              <ListArticles
                listQuery={listCorporatePosts}
                getQuery={getCorporatePost}
                categoryName="corporate"
                createMutation={createCorporatePost}
                idName={"corporateItemPostsId"}
                listTitle="listCorporatePosts"
                createTitle="createCorporatePost"
                route="/corporate/post/"
                initialFormState={initialFormStateCorporate}
              />
            }
          />
          <Route
            path="/weddings/:id/blog/:blogId"
            element={
              <ListArticles
                listQuery={listWeddingsPosts}
                getQuery={getWeddingsPost}
                categoryName="weddings"
                createMutation={createWeddingsPost}
                idName={"weddingsItemPostsId"}
                listTitle="listWeddingsPosts"
                createTitle="createWeddingsPost"
                route="/weddings/post/"
                initialFormState={initialFormStateWeddings}
              />
            }
          />
          <Route
            path="/private/:id/blog/:blogId"
            element={
              <ListArticles
                listQuery={listPrivatePosts}
                getQuery={getPrivatePost}
                categoryName="private"
                createMutation={createPrivatePost}
                idName={"privateItemPostsId"}
                listTitle="listPrivatePosts"
                createTitle="createPrivatgPost"
                route="/private/post/"
                initialFormState={initialFormPrivate}
              />
            }
          />
          <Route
            path="/nonProfit/:id/blog/:blogId"
            element={
              <ListArticles
                listQuery={listNonProfitPosts}
                getQuery={getNonProfitPost}
                categoryName="nonprofit"
                createMutation={createNonProfitPost}
                idName={"nonProfitItemPostsId"}
                listTitle="listNonProfitPosts"
                createTitle="createNonProfitPost"
                route="/nonProfit/post/"
                initialFormState={initialFormStateNonprofit}
              />
            }
          />
          <Route
            path="/corporate/post/:postID"
            element={
              <ChangeArticle
                getQuery={getCorporatePost}
                getQueryTitle={"getCorporatePost"}
                deleteMutation={deleteCorporatePost}
                deleteMutationTitle={"deleteCorporatePost"}
                getUpdatedData={getUpdatedData}
                idName={"corporateItemPostsId"}
                updateMutation={updateCorporatePost}
                route="/corporate/:id/blog/:blogId"
              />
            }
          />
          <Route
            path="/weddings/post/:postID"
            element={
              <ChangeArticle
                getQuery={getWeddingsPost}
                getQueryTitle={"getWeddingsPost"}
                deleteMutation={deleteWeddingsPost}
                deleteMutationTitle={"deleteWeddingsPost"}
                getUpdatedData={getUpdatedData}
                idName={"weddingsItemPostsId"}
                updateMutation={updateWeddingsPost}
                route="/weddings/:id/blog/:blogId"
              />
            }
          />
          <Route
            path="/private/post/:postID"
            element={
              <ChangeArticle
                getQuery={getPrivatePost}
                getQueryTitle={"getPrivatePost"}
                deleteMutation={deletePrivatePost}
                deleteMutationTitle={"deletePrivatePost"}
                getUpdatedData={getUpdatedData}
                idName={"privateItemPostsId"}
                updateMutation={updatePrivatePost}
                route="/private/:id/blog/:blogId"
              />
            }
          />
          <Route
            path="/nonProfit/post/:postID"
            element={
              <ChangeArticle
                getQuery={getNonProfitPost}
                getQueryTitle={"getNonProfitPost"}
                deleteMutation={deleteNonProfitPost}
                deleteMutationTitle={"deleteNonProfitPost"}
                getUpdatedData={getUpdatedData}
                idName={"nonProfitItemPostsId"}
                updateMutation={updateNonProfitPost}
                route="/nonProfit/:id/blog/:blogId"
              />
            }
          />
        </Routes>
      </Layout>
    </Router>
  );
}

export default withAuthenticator(App);
