import React, { useState, useEffect } from 'react';
import { API, Storage } from 'aws-amplify';
import {
  createCorporate,
  createNonProfit,
  createPrivate,
  createWeddings,
  deleteCorporate,
  deleteNonProfit,
  deletePrivate,
  deleteWeddings,
} from '../graphql/mutations';
import {
  listCorporates,
  listNonProfits,
  listPrivates,
  listWeddings,
} from '../graphql/queries';
import { getImageUrl } from '../utils';

const initialFormState = {
  name: '',
};

export default function useContent(contentType) {
  const [content, setContent] = useState([]);
  const [formData, setFormData] = useState(initialFormState);
  const [onPopup, setOnPopup] = useState('');

  // Fetch content based on contentType (blogs, news, or interviews)
  async function fetchContent() {
    const query =
      contentType === 'weddings'
        ? listWeddings
        : contentType === 'corporate'
        ? listCorporates
        : contentType === 'private'
        ? listPrivates
        : contentType === 'nonProfit'
        ? listNonProfits
        : null;
    const queryName =
      contentType === 'weddings'
        ? 'listWeddings'
        : contentType === 'corporate'
        ? 'listCorporates'
        : contentType === 'private'
        ? 'listPrivates'
        : contentType === 'nonProfit'
        ? 'listNonProfits'
        : null;
    if (query) {
      const apiData = await API.graphql({ query });

      const contentFromAPI = apiData.data[queryName]?.items;

      await Promise.all(
        contentFromAPI.map(async (item) => {
          if (item.image) {
            const image = getImageUrl(item.image);
            item.imageUrl = image;
          }
          return item;
        })
      );

      setContent(contentFromAPI);
    }
  }

  // Handle changes to the form input fields
  async function onChange(e) {
    if (!e.target.files[0]) return;
    const file = e.target.files[0];
    setFormData({ ...formData, image: file.name });
    await Storage.put(file.name, file);
    fetchContent();
  }

  // Create new content
  async function createContent() {
    if (!formData.name) return;

    const mutation =
      contentType === 'weddings'
        ? createWeddings
        : contentType === 'corporate'
        ? createCorporate
        : contentType === 'private'
        ? createPrivate
        : contentType === 'nonProfit'
        ? createNonProfit
        : null;
    const mutationName =
      contentType === 'weddings'
        ? 'createWeddings'
        : contentType === 'corporate'
        ? 'createCorporate'
        : contentType === 'private'
        ? 'createPrivate'
        : contentType === 'nonProfit'
        ? 'createNonProfit'
        : null;

    if (mutation) {
      const { data } = await API.graphql({
        query: mutation,
        variables: { input: formData },
      });

      const newContent = data?.[mutationName];
      if (newContent?.image) {
        const image = getImageUrl(newContent.image);
        newContent.imageUrl = image;
      }

      setContent([...content, newContent]);
      setFormData(initialFormState);
    }
  }

  // Delete content
  async function deleteContent(id = onPopup?.id, version = onPopup?.version) {
    if (id && version) {
      const mutation =
        contentType === 'weddings'
          ? deleteWeddings
          : contentType === 'corporate'
          ? deleteCorporate
          : contentType === 'private'
          ? deletePrivate
          : contentType === 'nonProfit'
          ? deleteNonProfit
          : null;
      const mutationName =
        contentType === 'weddings'
          ? 'deleteWeddings'
          : contentType === 'corporate'
          ? 'deleteCorporate'
          : contentType === 'private'
          ? 'deletePrivate'
          : contentType === 'nonProfit'
          ? 'deleteNonProfit'
          : null;

      if (mutation) {
        const { data } = await API.graphql({
          query: mutation,
          variables: { input: { id, _version: version } },
        });

        if (data?.[mutationName]?.id) {
          const newContentArray = content.filter(
            (item) => item.id !== data[mutationName].id
          );
          setContent(newContentArray);
        }
      }
    }
  }

  useEffect(() => {
    fetchContent();
  }, [contentType]);

  return {
    content,
    formData,
    onPopup,
    setOnPopup,
    onChange,
    createContent,
    deleteContent,
    setFormData,
  };
}
