import React, { useState } from "react";
import { Button } from "@aws-amplify/ui-react";
import styled from "@emotion/styled";
import PopupWarning from "../components/PopupWarning";
import StartCards from "../components/StartCards";
import CreateCard from "../components/CreateCard";
import AuthorBlock from "../components/AuthorBlock";
import useCategoryContent from "../utils/useCategoryContent";
import { useParams } from "react-router-dom";

/// CSS
const CreateContainer = styled.section`
  display: flex;
  flex-direction: column;
  > button {
    width: fit-content;
  }
`;
const CreatBlock = styled.section`
  display: flex;
  flex-wrap: wrap;
`;
const BlogsContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
  flex-flow: wrap row;
  margin: 50px 0px;
`;

/// JS
function Category({ category }) {
  const { id } = useParams();
  console.log(useParams());
  // fetch data
  const {
    content: categoryData,
    formData: formDataCategory,
    onPopup: onPopupCategory,
    setOnPopup: setOnPopupCategory,
    onChange: onChangeCategory,
    createContent: createCategory,
    deleteContent: deleteCategory,
    setFormData: setFormDataCategory,
  } = useCategoryContent(category);

  const [showCreateBlock, setShowCreateBlock] = useState(false);
  const handleClosePopup = () => {
    setOnPopupCategory("");
  };
  return (
    <>
      <AuthorBlock />
      <BlogsContainer>
        <StartCards
          content={categoryData}
          setOnPopup={setOnPopupCategory}
          href={`${category}/${id}/blog`}
        />

        <PopupWarning
          onPopup={onPopupCategory}
          handleClosePopup={handleClosePopup}
          onDelete={deleteCategory}
        />
      </BlogsContainer>
      <CreateContainer>
        <Button
          variation="link"
          size="small"
          onClick={() => setShowCreateBlock(!showCreateBlock)}
        >
          Show create block
        </Button>
        {showCreateBlock && (
          <CreatBlock>
            <CreateCard
              createSection={createCategory}
              onChangeImage={onChangeCategory}
              formData={formDataCategory}
              setFormData={setFormDataCategory}
            />
          </CreatBlock>
        )}
      </CreateContainer>
    </>
  );
}

export default Category;
